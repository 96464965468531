import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import _reduce from 'lodash/reduce';
import _isNil from 'lodash/isNil';
import debounce from 'lodash/debounce';

import {
  convertObjectToQueryString,
  convertUrlStringToObject,
  getValueFromLocalStorage,
} from '../../../utils/common';
import { api } from '../../../utils/apiRequest';
import { Button, Loader, PaginationInfoType, Tabs, toasts } from '../../../ui-component';
import type { Options } from '../../../components/FormComponents';

import OrderTable from './component/OrderTable';
import type { OrderItemListType, OrderListType } from './type';
import { OrderListStyled } from './style';
import { defaultPaginationObj, pageSize, tabListConfig } from './constant';

const OrderList = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [data, setListData] = useState<OrderListType[]>([]);
  const [outfitData, setOutfitData] = useState<OrderItemListType[]>([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [childTableIndex, setChildTableIndex] = useState(-1);
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfoType>(defaultPaginationObj);
  const [outfitStatusFilter, setOutfitStatusFilter] = useState<Record<number, Options[]>>({
    0: [],
  });
  const [filterValues, setFilterValues] = useState<Record<number, Record<string, any>>>({
    0: {},
  });
  const [isLoading, setIsLoading] = useState(false);

  const [urlQueryParams, setUrlQueryParams] = useState<Record<string, any>>({});

  const tabNames = tabListConfig.map((item) => item.label);

  useEffect(() => {
    setUrlQueryParams(convertUrlStringToObject(location.search));
  }, [location.search]);

  useEffect(() => {
    if ('tabName' in urlQueryParams) {
      const index = tabListConfig.findIndex(
        (obj) => obj.label.toLowerCase() === urlQueryParams['tabName'].toLowerCase()
      );

      setActiveIndex(index === -1 ? 0 : index);
    }

    if ('pageNo' in urlQueryParams) {
      setPaginationInfo({
        ...paginationInfo,
        currentPage: parseInt(urlQueryParams['pageNo']),
      });
    }
  }, [JSON.stringify(urlQueryParams)]);

  useEffect(() => {
    if ('status' in urlQueryParams) {
      const statusList = urlQueryParams['status'].split(' ');
      const statusOptions = tabListConfig[activeIndex].statusFilterOptions;

      setOutfitStatusFilter({
        ...outfitStatusFilter,
        [activeIndex]: statusOptions.filter((obj) => statusList.includes(obj.value)),
      });
    } else {
      setOutfitStatusFilter({
        ...outfitStatusFilter,
        [activeIndex]: [],
      });
    }

    if ('filters' in urlQueryParams) {
      const filters = JSON.parse(urlQueryParams['filters']);
      setFilterValues({
        ...filterValues,
        [activeIndex]: {
          ...filterValues[activeIndex],
          ...filters,
        },
      });
    } else {
      setFilterValues({ [activeIndex]: {} });
    }
  }, [JSON.stringify(urlQueryParams), activeIndex]);

  useEffect(() => {
    const currentStatusOptions = outfitStatusFilter[activeIndex];
    let queryParams = {};

    if (_isNil(currentStatusOptions) || currentStatusOptions.length === 0) {
      queryParams = {
        ...tabListConfig[activeIndex].queryFilters,
      };
    } else {
      queryParams = {
        ...tabListConfig[activeIndex].queryFilters,
        order_item_status_list: outfitStatusFilter[activeIndex]
          .map((options) => options.value)
          .join(','),
      };
    }

    const currentFilterOptions = filterValues[activeIndex];

    if (!_isNil(currentFilterOptions) && !_isNil(currentFilterOptions.deliveryDateFilter)) {
      const { startDate, endDate } = currentFilterOptions.deliveryDateFilter;
      queryParams = {
        ...queryParams,
        delivery_date_from: startDate,
        delivery_date_till: endDate,
      };
    }

    void debouncedGetOrderList(queryParams, paginationInfo);
  }, [
    JSON.stringify(outfitStatusFilter),
    JSON.stringify(paginationInfo.currentPage),
    JSON.stringify(filterValues),
  ]);

  const getOrderList = async (
    queryParams: Record<string, any> = {},
    paginationInfo: PaginationInfoType = defaultPaginationObj,
    size = pageSize
  ) => {
    try {
      setIsLoading(true);
      const boutique_id = getValueFromLocalStorage('boutique_id');

      const queryString = _reduce(
        queryParams,
        (querString, value, key) => querString + `${key}=${value}&`,
        ''
      );

      const pageCount = paginationInfo.currentPage;

      const response = await api.getRequest(
        `order/?${queryString}count=${size}&page_count=${pageCount}&boutique_id=${boutique_id}`
      );

      const { status, data } = response;

      if (status) {
        setListData(data?.data ?? []);

        setPaginationInfo({
          ...paginationInfo,
          pageCount: getTotalPageCount(data?.total_records_count ?? 0, pageSize),
        });

        setChildTableIndex(-1);
      } else {
        setListData([]);
      }
    } catch (error) {
      if (error instanceof Error) {
        toasts('error', error.message, 'order-list-error');
      }

      setListData([]);
    }

    setIsLoading(false);
  };

  const debouncedGetOrderList = useCallback(
    debounce((...args) => {
      void getOrderList(...args);
    }, 1000),
    []
  );

  const getOutfitData = (order_id: number) => {
    const currentStatusOptions = outfitStatusFilter[activeIndex];
    let queryParams: Record<string, any> = { order_id };

    if (_isNil(currentStatusOptions) || currentStatusOptions.length === 0) {
      queryParams = {
        ...queryParams,
        // ...tabListConfig[activeIndex].queryFilters,
      };
    } else {
      queryParams = {
        ...queryParams,
        // ...tabListConfig[activeIndex].queryFilters,
        order_item_status_list: outfitStatusFilter[activeIndex]
          .map((options) => options.value)
          .join(','),
      };
    }

    void makeOutfitDataRequest(queryParams);
  };

  const makeOutfitDataRequest = async (queryParams: Record<string, any> = {}) => {
    try {
      setIsLoading(true);
      const boutique_id = getValueFromLocalStorage('boutique_id');

      const queryString = _reduce(
        queryParams,
        (querString, value, key) => querString + `${key}=${value}&`,
        ''
      );

      const response = await api.getRequest(`order_item?${queryString}boutique_id=${boutique_id}`);

      const { status, data } = response;

      if (status) {
        setOutfitData(data?.data ?? []);
      }
    } catch (error) {
      if (error instanceof Error) {
        toasts('error', error.message, 'order-list-error');
      }
    }

    setIsLoading(false);
  };

  const onTabChange = (index: number) => {
    if (index !== activeIndex) {
      setActiveIndex(index);
      setPaginationInfo(defaultPaginationObj);
      clearChildTableData();

      const updatedQueryString = convertObjectToQueryString({
        tabName: tabListConfig[index].label,
      });

      navigate(`/orders-list?${updatedQueryString}`);
    }
  };

  const getTotalPageCount = (totalRecords: number, pageSize: number) => {
    const num = totalRecords / pageSize;

    return Math.floor(num) + (totalRecords % pageSize === 0 ? 0 : 1);
  };

  const handlePageChange = (pageNumber: number) => {
    clearChildTableData();

    if (pageNumber !== paginationInfo.currentPage) {
      const queryString = convertObjectToQueryString({
        ...urlQueryParams,
        pageNo: pageNumber,
      });

      navigate(`/orders-list?${queryString}`);
    }
  };

  const handleSortColumn = (key: string) => {
    const currentStatusOptions = outfitStatusFilter[activeIndex];
    let queryParams: Record<string, any> = {
      sort_key: key,
    };

    if (_isNil(currentStatusOptions) || currentStatusOptions.length === 0) {
      queryParams = {
        ...queryParams,
        ...tabListConfig[activeIndex].queryFilters,
      };
    } else {
      queryParams = {
        ...queryParams,
        ...tabListConfig[activeIndex].queryFilters,
        order_item_status_list: outfitStatusFilter[activeIndex]
          .map((options) => options.value)
          .join(','),
      };
    }

    void getOrderList(queryParams, paginationInfo);
  };

  const updateOutfitStatusFilter = (value: Options[]) => {
    const queryString = convertObjectToQueryString({
      ...urlQueryParams,
      status: value.map((obj) => obj.value).join(' '),
      pageNo: 1,
    });

    navigate(`/orders-list?${queryString}`);
  };

  const updateFilter = (key: string, value: any) => {
    const queryString = convertObjectToQueryString({
      ...urlQueryParams,
      filters: JSON.stringify({ [key]: value }),
      pageNo: 1,
    });

    navigate(`/orders-list?${queryString}`);
  };

  const clearChildTableData = () => {
    setOutfitData([]);
    setChildTableIndex(-1);
  };

  return (
    <OrderListStyled>
      <Tabs
        defaultIndex={activeIndex}
        isSticky={false}
        tabNameList={tabNames}
        onTabChange={onTabChange}
      >
        <OrderTable
          data={data}
          outfitData={outfitData}
          statusFilterOptions={tabListConfig[activeIndex].statusFilterOptions}
          outfitStatus={outfitStatusFilter[activeIndex]}
          updateOutfitStatus={updateOutfitStatusFilter}
          filterOptions={tabListConfig[activeIndex].dateFilterOptions ?? {}}
          filterValue={filterValues[activeIndex]}
          updateFilter={updateFilter}
          pageInfo={paginationInfo}
          handlePageChange={handlePageChange}
          getOutfitData={getOutfitData}
          childTableIndex={childTableIndex}
          setChildTableIndex={setChildTableIndex}
          handleSortColumn={handleSortColumn}
          urlQueryParams={urlQueryParams}
        />
        <OrderTable
          data={data}
          outfitData={outfitData}
          statusFilterOptions={tabListConfig[activeIndex].statusFilterOptions}
          outfitStatus={outfitStatusFilter[activeIndex]}
          updateOutfitStatus={updateOutfitStatusFilter}
          filterOptions={tabListConfig[activeIndex].dateFilterOptions ?? {}}
          filterValue={filterValues[activeIndex]}
          updateFilter={updateFilter}
          pageInfo={paginationInfo}
          handlePageChange={handlePageChange}
          getOutfitData={getOutfitData}
          childTableIndex={childTableIndex}
          setChildTableIndex={setChildTableIndex}
          handleSortColumn={handleSortColumn}
          urlQueryParams={urlQueryParams}
        />
        <OrderTable
          data={data}
          outfitData={outfitData}
          statusFilterOptions={tabListConfig[activeIndex].statusFilterOptions}
          outfitStatus={outfitStatusFilter[activeIndex]}
          updateOutfitStatus={updateOutfitStatusFilter}
          filterOptions={tabListConfig[activeIndex].dateFilterOptions ?? {}}
          filterValue={filterValues[activeIndex]}
          updateFilter={updateFilter}
          pageInfo={paginationInfo}
          handlePageChange={handlePageChange}
          getOutfitData={getOutfitData}
          childTableIndex={childTableIndex}
          setChildTableIndex={setChildTableIndex}
          handleSortColumn={handleSortColumn}
          urlQueryParams={urlQueryParams}
        />
        <OrderTable
          data={data}
          outfitData={outfitData}
          statusFilterOptions={tabListConfig[activeIndex].statusFilterOptions}
          outfitStatus={outfitStatusFilter[activeIndex]}
          updateOutfitStatus={updateOutfitStatusFilter}
          filterOptions={tabListConfig[activeIndex].dateFilterOptions ?? {}}
          filterValue={filterValues[activeIndex]}
          updateFilter={updateFilter}
          pageInfo={paginationInfo}
          handlePageChange={handlePageChange}
          getOutfitData={getOutfitData}
          childTableIndex={childTableIndex}
          setChildTableIndex={setChildTableIndex}
          handleSortColumn={handleSortColumn}
          urlQueryParams={urlQueryParams}
        />
        <OrderTable
          data={data}
          outfitData={outfitData}
          statusFilterOptions={tabListConfig[activeIndex].statusFilterOptions}
          outfitStatus={outfitStatusFilter[activeIndex]}
          updateOutfitStatus={updateOutfitStatusFilter}
          filterOptions={tabListConfig[activeIndex].dateFilterOptions ?? {}}
          filterValue={filterValues[activeIndex]}
          updateFilter={updateFilter}
          pageInfo={paginationInfo}
          handlePageChange={handlePageChange}
          getOutfitData={getOutfitData}
          childTableIndex={childTableIndex}
          setChildTableIndex={setChildTableIndex}
          handleSortColumn={handleSortColumn}
          urlQueryParams={urlQueryParams}
        />
        <OrderTable
          data={data}
          outfitData={outfitData}
          statusFilterOptions={tabListConfig[activeIndex].statusFilterOptions}
          outfitStatus={outfitStatusFilter[activeIndex]}
          updateOutfitStatus={updateOutfitStatusFilter}
          filterOptions={tabListConfig[activeIndex].dateFilterOptions ?? {}}
          filterValue={filterValues[activeIndex]}
          updateFilter={updateFilter}
          pageInfo={paginationInfo}
          handlePageChange={handlePageChange}
          getOutfitData={getOutfitData}
          childTableIndex={childTableIndex}
          setChildTableIndex={setChildTableIndex}
          handleSortColumn={handleSortColumn}
          urlQueryParams={urlQueryParams}
        />
      </Tabs>
      <Button className="create-order-btn" onClick={() => navigate('/select-customer')}>
        Create Order
      </Button>
      <Loader showLoader={isLoading} />
    </OrderListStyled>
  );
};

export default OrderList;
