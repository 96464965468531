import styled from 'styled-components';

export const CheckboxGroupBtnContainer = styled.div``;

export const LabelContainer = styled.div`
  display: flex;
  margin-bottom: 10px;

  .mandatory {
    vertical-align: sub;
  }
`;

export const OptionContainer = styled.div`
  display: flex;
  gap: 16px;
`;
