import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import _isNil from 'lodash/isNil';
import { BiSolidDownload } from 'react-icons/bi';

import { api } from '../../../../../utils/apiRequest';
import {
  convertNumberOrStringToPriceFormat,
  getValueFromLocalStorage,
  handleDownloadInvoice,
} from '../../../../../utils/common';
import { positiveNumberRegex } from '../../../../../utils/regexValue';
import { moneyFormatSigns } from '../../../../../utils/contant';

import { DropdownField, InputField } from '../../../../../components/FormComponents';
import { Button, Modal, ModalMethods, Text, toasts } from '../../../../../ui-component';
import type { OrderPriceBreakupType } from '../../../CreateOrder/type';

import { paymentModeOptions } from './constant';
import {
  InvoiceSummaryContainer,
  InvoiceSummaryContent,
  ModalBody,
  PaymentOptionItem,
} from './style';

type InvoiceSummaryProps = {
  order_id: number;
  orderStatus: string;
  setIsLoading: (data: boolean) => void;
};

const InvoiceSummary = ({ order_id, orderStatus, setIsLoading }: InvoiceSummaryProps) => {
  const ref = useRef<ModalMethods>(null);
  const navigate = useNavigate();

  const [invoiceLink, setInvoiceLink] = useState('');
  const [data, setData] = useState<Record<string, any>>();

  const [recieveAmount, setRecieveAmount] = useState<string | undefined>();
  const [err, setErr] = useState<string>();
  const [paymentMethod, setPaymentMethod] = useState<Record<string, any>>(paymentModeOptions[0]);

  useEffect(() => {
    if (!_isNil(order_id)) {
      void getInvoiceLink();
      void getInvoiceDetails();
    }
  }, [order_id]);

  const getInvoiceLink = async () => {
    try {
      const boutique_id = getValueFromLocalStorage('boutique_id');

      const payload = {
        entity_type: 'invoice',
        entity_id: order_id,
        meta_data: {
          boutique_id,
        },
      };

      setIsLoading(true);
      const response = await api.postRequest(`storage/file/link`, payload);

      const { status, data } = response;

      if (status) {
        setInvoiceLink(data.link);
      }
    } catch (err) {
      if (err instanceof Error) {
        toasts('error', err.message, 'get-invoice-link-error');
      }
    }

    setIsLoading(false);
  };

  const getInvoiceDetails = async () => {
    try {
      setIsLoading(true);
      const boutique_id = getValueFromLocalStorage('boutique_id');

      const response = await api.getRequest(
        `order/${order_id}/invoice_detail?boutique_id=${boutique_id}`
      );

      const { status, data } = response;

      if (status) {
        setData(data);
      }
    } catch (err) {
      if (err instanceof Error) {
        toasts('error', err.message, 'get-invoice-summary-error');
      }
    }

    setIsLoading(false);
  };

  const handleChange = (value: string, errMsg?: string) => {
    setRecieveAmount(value);
    setErr(errMsg);
  };

  const handleConfirmBtn = () => {
    if (!_isNil(err) && !_isNil(recieveAmount)) {
      ref.current?.show();
    } else {
      toasts('error', 'Please fill correct amount', 'amount-error');
    }
  };

  const handleModalClose = () => {
    ref.current?.hide();
  };

  const handleModalSave = async () => {
    try {
      const payload = {
        payment_mode: paymentMethod.value,
        payment_date: moment(new Date()).format('YYYY/DD/MM'),
        amount: parseFloat(recieveAmount ?? '0'),
      };
      const boutique_id = getValueFromLocalStorage('boutique_id');
      setIsLoading(true);

      const response = await api.postRequest(
        `order/${order_id}/recieve_payment?boutique_id=${boutique_id}`,
        payload
      );
      const { status } = response;

      if (status) {
        ref.current?.hide();

        void getInvoiceDetails();
        setRecieveAmount('');
      }
    } catch (err) {
      if (err instanceof Error) {
        toasts('error', err.message, 'recieve-payment-err');
      }
    }

    setIsLoading(false);
  };

  const CustomOption = (props: any) => {
    const { innerProps, innerRef, isSelected } = props;

    return (
      <PaymentOptionItem $isSelected={isSelected as boolean} ref={innerRef} {...innerProps}>
        <div className="img">{props.data.icon}</div>
        <div className="revision-text">
          <Text color={isSelected ? 'white' : 'black'} fontWeight={600}>
            {props.data.label}
          </Text>
        </div>
      </PaymentOptionItem>
    );
  };

  const handleSelectPayment = (value: any) => {
    setPaymentMethod(value);
  };

  //eslint-disable-next-line
  const handleShare = (link: string) => {
    // const whatsappURL = `whatsapp://send?text=${encodeURIComponent(link)}`;
    // window.location.href = whatsappURL;

    const whatsappURL = `https://web.whatsapp.com/send?text=${encodeURIComponent(link)}`;
    window.open(whatsappURL, '_blank');
  };

  const handleEditButton = () => {
    navigate(`/orders/${order_id}?formType=edit`);
  };

  if (_isNil(data)) {
    return null;
  }

  return (
    <InvoiceSummaryContainer>
      <div className="invoice-header">
        <Text fontWeight={500}> Order Details</Text>
        <div className="header-btn-container">
          {orderStatus === 'Drafted' && (
            <Button appearance="outlined" onClick={handleEditButton}>
              Edit
            </Button>
          )}
          <Button
            appearance="outlined"
            onClick={() => {
              handleDownloadInvoice(
                invoiceLink,
                `${data?.order_summary.boutique_order_id ?? ''}_invoice.pdf`
              );

              //   window.open(invoiceLink, '_blank');
            }}
            trailingIcon={<BiSolidDownload color="var(--color-primary)" size={16} />}
          >
            Download pdf
          </Button>
        </div>

        {/* <Button appearance="outlined" onClick={() => handleShare(invoiceLink)}>
          Share
        </Button> */}
      </div>
      <InvoiceSummaryContent>
        <div className="content-container">
          <div>
            <Text fontWeight={600} size="xxxl">
              Invoice
            </Text>
            <Text fontWeight={700}>{data?.boutique_name ?? '-'}</Text>
          </div>
          <div className="invoice-right-info">
            <div className="invoice-info-text">
              <Text fontWeight={600}>Invoice Date:</Text>
              <Text fontWeight={500} color="black">
                {!_isNil(data?.invoice_date_time)
                  ? new Date(data?.invoice_date_time).toDateString()
                  : '-'}
              </Text>
            </div>
            <div className="invoice-info-text">
              <Text fontWeight={600}>Invoice Number:</Text>
              <Text fontWeight={500} color="black">
                {data?.order_summary.invoice_no}
              </Text>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="customer-info">
            <div className="customer-info-text">
              <Text fontWeight={600}>Customer Name:</Text>
              <Text fontWeight={500} color="black">
                {data?.customer_name ?? '-'}
              </Text>
            </div>
            <div className="customer-info-text">
              <Text fontWeight={600}>Order Number:</Text>
              <Text fontWeight={500} color="black">
                {data?.order_summary.boutique_order_id ?? '-'}
              </Text>
            </div>
            <div className="customer-info-text">
              <Text fontWeight={600}>Order Receive Date:</Text>
              <Text fontWeight={500} color="black">
                {!_isNil(data?.recieve_date_time)
                  ? new Date(data?.recieve_date_time).toDateString()
                  : '-'}
              </Text>
            </div>
            <div className="customer-info-text">
              <Text fontWeight={600}>Payment Mode:</Text>
              <Text fontWeight={500} color="black">
                {paymentModeOptions.find((option) => option.value === data?.payment_mode ?? 1)
                  ?.label ?? '-'}
              </Text>
            </div>
          </div>

          <div className="amount-summary-container">
            <div className="outfit-summary-amount-box">
              <Text size="large" fontWeight={600}>
                Order Summary
              </Text>
              <div className="outfit-price-list">
                {data?.order_summary.order_item_summary_list.map((itemObj: any, index: number) => {
                  const total =
                    itemObj?.price_breakup?.reduce(
                      (sum: number, currPriceObj: OrderPriceBreakupType) =>
                        sum + (currPriceObj?.value ?? 0) * currPriceObj.component_quantity,
                      0
                    ) ?? 0;

                  return (
                    <div key={index}>
                      <div className="amount-item">
                        <Text color="black" fontWeight={700}>
                          {itemObj.outfit_alias}
                        </Text>
                        <Text color="black" fontWeight={700}>
                          {`${moneyFormatSigns.rupee} ${convertNumberOrStringToPriceFormat(
                            total ?? 0
                          )}`}
                        </Text>
                      </div>

                      <div className="price-breakup-list">
                        {itemObj?.price_breakup?.map(
                          (priceObj: OrderPriceBreakupType, index: number) => (
                            <div key={index} className="amount-item">
                              <Text size="small" color="black" fontWeight={500}>
                                {priceObj.component}
                              </Text>
                              <Text size="small" color="black" fontWeight={500}>
                                {`${priceObj.component_quantity} x ${priceObj.value} = `}
                                {` ${moneyFormatSigns.rupee} ${convertNumberOrStringToPriceFormat(
                                  priceObj.component_quantity * (priceObj?.value ?? 0)
                                )}`}
                              </Text>
                            </div>
                          )
                        )}
                      </div>

                      <Text size="small" fontWeight={500} className="date-field-style">
                        {`Delivery Date: ${new Date(itemObj.delivery_date).toDateString()}`}{' '}
                      </Text>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="amount-box">
              <div className="amount-item">
                <Text color="black" fontWeight={600}>
                  Total:
                </Text>
                <Text color="black" fontWeight={600}>
                  {`${moneyFormatSigns.rupee} ${convertNumberOrStringToPriceFormat(
                    data?.order_summary?.total_order_amount ?? 0
                  )}`}
                </Text>
              </div>
            </div>

            <div className="amount-box">
              <div className="amount-item">
                <Text color="black" fontWeight={600}>
                  Advance (if any):
                </Text>
                <Text color="black" fontWeight={600}>
                  {`${moneyFormatSigns.rupee} ${convertNumberOrStringToPriceFormat(
                    data?.order_summary?.order_advance_recieved ?? 0
                  )}`}
                </Text>
              </div>
            </div>

            <div className="amount-box">
              <div className="amount-item">
                <Text color="black" fontWeight={600}>
                  Balance Due:
                </Text>
                <Text fontWeight={600} color="tertiary">
                  {`${moneyFormatSigns.rupee} ${convertNumberOrStringToPriceFormat(
                    (data?.order_summary?.total_order_amount ?? 0) -
                      (data?.order_summary?.order_advance_recieved ?? 0)
                  )}`}
                </Text>
              </div>
            </div>

            {data?.order_summary?.total_order_amount !==
              data?.order_summary?.order_advance_recieved &&
              orderStatus !== 'Drafted' && (
                <>
                  <div className="advance-amount-box">
                    <Text color="black" fontWeight={600}>
                      Recieve Balance Due
                    </Text>
                    <InputField
                      type="text"
                      required={false}
                      value={recieveAmount}
                      placeholder="0"
                      className="amount-field"
                      regex={positiveNumberRegex}
                      onChange={(value, errorMsg) => handleChange(value, errorMsg)}
                    />
                  </div>
                  <div className="btn-container">
                    <Button onClick={handleConfirmBtn}>Confirm</Button>
                  </div>
                </>
              )}
          </div>
        </div>
        <div className="footer-content">
          <Text size="xxxl" fontWeight={600}>
            Thank You for your business
          </Text>
          <Text fontWeight={600}>Terms and conditions</Text>
          <Text fontWeight={500}>
            *Once confirmed, any advance amount paid towards the order cannot be refunded.
          </Text>
          <Text fontWeight={500}>*Goods once sold will not be taken back.</Text>
          <Text fontWeight={500}>
            *We do not give a guarantee of colors. We advise dry cleaning only.
          </Text>
        </div>
      </InvoiceSummaryContent>

      <Modal
        ref={ref}
        size="medium"
        title="Select mode of payment"
        onModalClose={handleModalClose}
        onModalSuccess={handleModalSave}
      >
        <ModalBody>
          <DropdownField
            label=""
            options={paymentModeOptions}
            value={
              !_isNil(paymentMethod)
                ? { label: paymentMethod.label, value: paymentMethod.value }
                : undefined
            }
            onChange={handleSelectPayment}
            CustomOption={CustomOption}
          />
        </ModalBody>
      </Modal>
    </InvoiceSummaryContainer>
  );
};

export default InvoiceSummary;
