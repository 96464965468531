import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';

import { api } from '../../../utils/apiRequest';
import {
  getValueFromLocalStorage,
  setDataAtKeyInNestedObject,
  validateDataBasedOnConfig,
} from '../../../utils/common';
import { updateCustomerDetails } from '../../Order/CreateOrder/reducer';
import { PlusIcon } from '../../../assets/icons';
import { Button, Loader, Modal, ModalMethods, Text, toasts } from '../../../ui-component';

import { CustomerDetailsErrorType, CustomerDetailsType, listDataType } from '../types';

import Details from './components/Details';
import Card from './components/Card';

import { Container, ContentContainer, Header, ListContainer } from './style';
import { validateCustomerDetailsObject } from './constant';

const CustomerList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const createCustomerRef = useRef<ModalMethods>(null);

  const [listData, setListData] = useState<listDataType[]>([]);
  const [isLoading, setIsloading] = useState(false);

  const [data, setData] = useState<CustomerDetailsType>();
  const [errorData, setErrorData] = useState<CustomerDetailsErrorType>();

  useEffect(() => {
    void getListData();
  }, []);

  const getListData = async () => {
    const boutique_id = getValueFromLocalStorage('boutique_id');

    if (!_isNil(boutique_id)) {
      setIsloading(true);

      try {
        const response = await api.getRequest(`customers/?boutique_id=${boutique_id}`);
        const { status, data } = response;

        if (status) {
          setListData(
            !_isNil(data.boutique_customer_details) ? data.boutique_customer_details : []
          );
        }
      } catch (err) {
        if (err instanceof Error) {
          toasts('error', err.message, 'error-cutomer-list');
        }
      }
      setIsloading(false);
    } else {
      toasts('info', 'Boutique Not Exist', 'boutique-not-exists');
    }
  };

  const handleCardClick = (selectedCustomer: listDataType) => {
    if (location.pathname.includes('select-customer')) {
      dispatch(updateCustomerDetails({ data: selectedCustomer }));
      navigate(`/select-outfit`);
    }
  };

  const onModalSuccess = async () => {
    const boutique_id = getValueFromLocalStorage('boutique_id');

    const { isValid, errorData } = validateDataBasedOnConfig(validateCustomerDetailsObject, {
      ...data,
      boutique_id,
    });

    if (!isValid) {
      setErrorData(errorData as CustomerDetailsErrorType);
    }

    if (!_isUndefined(data) && !_isNil(boutique_id) && isValid) {
      const { customer_image_urls, ...rest } = data;

      const formattedData = {
        ...rest,
        age: +rest.age,
        phone_number: +rest.phone_number,
        customer_image_reference_id: customer_image_urls?.reference_id ?? '',
        boutique_id,
      };

      try {
        setIsloading(true);

        const response = await api.postRequest('customers/', formattedData);

        const { status } = response;

        if (status) {
          resetForm();
          createCustomerRef.current?.hide();
          void getListData();
        }
      } catch (err) {
        if (err instanceof Error) {
          toasts('error', err.message, 'create-customer-error');
        }
      }

      setIsloading(false);
    }
  };

  const handleChange = (objList: Array<{ value: any; key: string }>) => {
    let updatedData = structuredClone(data);

    objList.forEach((currObj) => {
      const { value, key } = currObj;
      updatedData = setDataAtKeyInNestedObject(updatedData, key, value) as CustomerDetailsType;
    });

    setData(updatedData);
  };

  const resetForm = () => {
    setData(undefined);
    setErrorData(undefined);
  };

  return (
    <Container>
      <Header>
        <Button
          size="small"
          leadingIcon={<PlusIcon />}
          onClick={() => createCustomerRef.current?.show()}
        >
          Add Customer
        </Button>
      </Header>
      <ContentContainer>
        <Text fontWeight={500}>Select Customer</Text>
        <ListContainer>
          {listData.map((data) => (
            <Card key={data.customer_id} data={data} onClick={handleCardClick} />
          ))}
        </ListContainer>
      </ContentContainer>

      <Modal
        ref={createCustomerRef}
        title="Add new customer"
        onModalClose={() => {
          createCustomerRef.current?.hide();
          resetForm();
        }}
        onModalSuccess={onModalSuccess}
        saveButtonText="Save & Continue"
      >
        <Details
          data={data}
          errorData={errorData}
          setErrorData={setErrorData}
          handleChange={handleChange}
          setIsloading={setIsloading}
        />
      </Modal>
      <Loader showLoader={isLoading} />
    </Container>
  );
};

export default CustomerList;
