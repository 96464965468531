import { useEffect, useRef, useState } from 'react';
import _reduce from 'lodash/reduce';
import _forEach from 'lodash/forEach';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import { api } from '../../../../../../../utils/apiRequest';
import { positiveNumberRegex } from '../../../../../../../utils/regexValue';
import { validateValueWithRegex } from '../../../../../../../utils/common';

import { DropdownField } from '../../../../../../../components/FormComponents';
import {
  Button,
  Loader,
  Modal,
  ModalMethods,
  Text,
  toasts,
} from '../../../../../../../ui-component';
import {
  EditIcon,
  MeasurementImageIcon,
  PenIcon,
  PlusIcon,
} from '../../../../../../../assets/icons';

import type { ImageObjType } from '../../../../../../../ui-component';
import type { OrderItemType } from '../../../../type';

import UploadImageButton from './UploadImageButton';
import ImageMeasurement from './ImageMeasurement';
import CustomMeasurements, {
  CustomMeasurementsDetailsType,
  CustomMeasurementsErrorType,
} from './CustomMeasurements';

import {
  MeasuementModalHeader,
  MeasurementBodyContent,
  MeasurementContainer,
  RevisionDropdownOption,
  SelectedMeasurement,
} from './style';

export type MeasurementRevisionObj = {
  created_at: string;
  customer_id: number;
  outfit_type: number;
  measurement_value: Record<string, any>;
  image_detail: ImageObjType & { image_url?: string };
  revision_id: number;
  label: string;
  value: string;
};

type MeaseurementsProps = {
  customer_id: number;
  selectedOutfitChipIndex: number;
  order_items: OrderItemType;
  handleChange: (value: any, key: string) => void;
};

const Measurements = ({
  customer_id,
  selectedOutfitChipIndex,
  order_items,
  handleChange,
}: MeaseurementsProps) => {
  const ref = useRef<ModalMethods>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [measurementRevisionList, setMeasurementRevisionList] = useState<MeasurementRevisionObj[]>(
    []
  );
  const [selectedRevision, setSelectedRevision] = useState<MeasurementRevisionObj>();

  const [customMeasurementDetails, setCustomMeasurementDetails] = useState<
    Record<number, CustomMeasurementsDetailsType[]>
  >({});
  const [imageMeasurement, setImageMeasurement] = useState<Record<number, ImageObjType | null>>({});
  const [isMeasurementUpdated, setIsMeasurementUpdated] = useState(false);

  const [customMeasurementErrors, setCustomMeasurementErrors] = useState<
    Record<number, CustomMeasurementsErrorType[]>
  >({});

  const [tabIndex, setTabIndex] = useState(0);
  const [tabNameList, setTabNameList] = useState<string[]>([]);

  const showImageMeasurements =
    !_isNil(imageMeasurement) &&
    !_isEmpty(imageMeasurement) &&
    !_isNil(imageMeasurement[selectedOutfitChipIndex]) &&
    !_isEmpty(imageMeasurement[selectedOutfitChipIndex]);

  const { outfit_type, measurement_revision_id, measurement_details } = order_items;

  const isEdit =
    !_isNil(measurement_revision_id) ||
    (!_isNil(measurement_details) &&
      (!_isNil(measurement_details.inner_measurement_details) ||
        !_isNil(measurement_details.measurement_image_link)));

  useEffect(() => {
    setCustomMeasurementDetails({});
    setCustomMeasurementErrors({});
  }, [outfit_type]);

  useEffect(() => {
    if (!_isNil(selectedRevision)) {
      const hasImage =
        !_isNil(selectedRevision.image_detail) &&
        !_isEmpty(selectedRevision.image_detail) &&
        !_isEmpty(selectedRevision.image_detail.image_url);

      setImageMeasurement({
        ...imageMeasurement,
        [selectedOutfitChipIndex]: hasImage
          ? {
              reference_id: selectedRevision.image_detail.reference_id,
              short_lived_url:
                selectedRevision.image_detail.short_lived_url ??
                selectedRevision.image_detail.image_url,
            }
          : null,
      });
    }
  }, [JSON.stringify(selectedRevision)]);

  useEffect(() => {
    if (
      !_isNil(measurementRevisionList) &&
      !_isEmpty(measurementRevisionList) &&
      !_isNil(measurement_revision_id)
    ) {
      setSelectedRevision(
        measurementRevisionList.find((obj) => obj.revision_id === measurement_revision_id)
      );
    }
  }, [measurement_revision_id, measurementRevisionList]);

  useEffect(() => {
    if (!_isNil(customMeasurementDetails)) {
      setTabNameList(
        customMeasurementDetails[selectedOutfitChipIndex]?.map(
          (custom) => custom.outfit_type_heading
        ) ?? []
      );
    }
  }, [JSON.stringify(customMeasurementDetails), selectedOutfitChipIndex]);

  const modalHeader = (
    <MeasuementModalHeader>
      <Text fontWeight={500}>Add Measurements (In Inches)</Text>

      <UploadImageButton
        imageMeasurement={imageMeasurement}
        selectedOutfitChipIndex={selectedOutfitChipIndex}
        setImageMeasurement={setImageMeasurement}
        setSelectedRevision={setSelectedRevision}
        setIsMeasurementUpdated={setIsMeasurementUpdated}
        setIsLoading={setIsLoading}
      />
    </MeasuementModalHeader>
  );

  const openModal = () => {
    ref.current?.show();
    setIsModalOpen(true);
    void getMeasurementRevisions();
  };

  const closeModal = () => {
    setIsModalOpen(false);
    ref.current?.hide();
    setTabIndex(0);
  };

  const closeOrBack = () => {
    if (tabIndex !== 0 && !showImageMeasurements) {
      setTabIndex(tabIndex - 1);

      return;
    }

    closeModal();
  };

  const getMeasurementRevisions = async () => {
    try {
      setIsLoading(true);
      const response = await api.getRequest(
        `measurement/revisions?customer_id=${customer_id}&outfit_type=${outfit_type}`
      );

      const { status, data } = response;

      if (status && !_isEmpty(data)) {
        let length = data.data.length + 1;
        const revisionList = data.data.map((revision: MeasurementRevisionObj) => {
          length--;

          return {
            ...revision,
            label: `Measurement ${length}`,
            value: `Measurement ${length}`,
          };
        });

        setSelectedRevision(revisionList[0]);
        setMeasurementRevisionList(revisionList);
      }
    } catch (err) {
      if (err instanceof Error) {
        toasts('error', err.message, 'measurement-revisions');
      }
    }
    setIsLoading(false);
  };

  const handleSelectMeasurement = (value: any) => {
    setSelectedRevision(value);
    setIsMeasurementUpdated(false);
  };

  const getFormattedMeasurements = () => {
    let isValidMeasurement = true;

    if (!showImageMeasurements) {
      // Validate filled measurements
      const updatedCustomMeasurementErrors = [...customMeasurementErrors[selectedOutfitChipIndex]];

      _forEach(customMeasurementDetails[selectedOutfitChipIndex], (measurement, parentIndex) => {
        _forEach(measurement.measurement_details_list, (value, index) => {
          if (!_isNil(value.value) && value.value !== '') {
            const { valid, message } = validateValueWithRegex(value.value, positiveNumberRegex);

            updatedCustomMeasurementErrors[parentIndex][index] = message;

            if (!valid) {
              isValidMeasurement = false;
            }
          }
        });
      });

      setCustomMeasurementErrors({
        ...customMeasurementErrors,
        [selectedOutfitChipIndex]: updatedCustomMeasurementErrors,
      });

      // If custom measurement Data is valid then format the data
      if (isValidMeasurement) {
        const formatedMeasurementData = {
          customer_id,
          outfit_type,
          scale: 'inch',
          measurements: _reduce(
            customMeasurementDetails[selectedOutfitChipIndex],
            (details, measurement) => {
              measurement.measurement_details_list.forEach((value) => {
                // const key =
                //   outfitMeasurementMap[outfit_type][measurement.outfit_type_heading][index];

                if (!_isNil(value) && !_isNil(value.value) && value.value !== '') {
                  details = {
                    ...details,
                    [value.name]: parseFloat(value.value),
                  };
                }
              });

              return details;
            },
            {}
          ),
        };

        return { formatedMeasurementData, isValidMeasurement };
      }
    } else if (!_isNil(imageMeasurement) && !_isNil(imageMeasurement[selectedOutfitChipIndex])) {
      const formatedMeasurementData = {
        customer_id,
        outfit_type,
        scale: 'inch',
        reference_id: imageMeasurement[selectedOutfitChipIndex]?.reference_id ?? '',
      };

      return { formatedMeasurementData, isValidMeasurement };
    }

    return { formatedMeasurementData: {}, isValidMeasurement: false };
  };

  const handleSaveData = async () => {
    if (tabIndex < tabNameList.length - 1 && !showImageMeasurements) {
      setTabIndex(tabIndex + 1);

      return;
    }

    if (isMeasurementUpdated) {
      const { formatedMeasurementData, isValidMeasurement } = getFormattedMeasurements();

      if (!_isEmpty(formatedMeasurementData) && isValidMeasurement) {
        try {
          setIsLoading(true);
          const response = await api.postRequest(`measurement/`, formatedMeasurementData);
          const { status, data } = response;

          if (status && !_isEmpty(data)) {
            const { measurement_revision_id } = data;
            handleChange(measurement_revision_id, 'measurement_revision_id');
            closeModal();
          }
        } catch (err) {
          if (err instanceof Error) {
            toasts('error', err.message, 'save-measurement');
          }
        }

        setIsLoading(false);
      }
    } else {
      handleChange(selectedRevision?.revision_id, 'measurement_revision_id');
      closeModal();
    }
  };

  const CustomOption = (props: any) => {
    const { innerProps, innerRef, isSelected } = props;

    const hasImage =
      !_isNil(props.data.image_detail) &&
      !_isNil(props.data.image_detail.image_url) &&
      !_isEmpty(props.data.image_detail.image_url);

    return (
      <RevisionDropdownOption $isSelected={isSelected as boolean} ref={innerRef} {...innerProps}>
        <div className="revision-img">{hasImage ? <MeasurementImageIcon /> : <PenIcon />}</div>
        <div className="revision-text">
          <Text color={isSelected ? 'white' : 'black'} fontWeight={600}>
            {props.data.label}
          </Text>
          <Text
            size="small"
            color={isSelected ? 'var(--color-lavender)' : 'var(--nightRider)'}
            fontWeight={400}
          >
            {`Recorded on : ${moment(new Date(props.data.created_at)).format('DD/MM/YYYY')}`}
          </Text>
        </div>
      </RevisionDropdownOption>
    );
  };

  return (
    <MeasurementContainer>
      <Text color="black" fontWeight={500}>
        Add Measurements
      </Text>
      <Button
        appearance="outlined"
        size="small"
        bgColor={isEdit ? 'var(--color-nightRider)' : 'primary'}
        leadingIcon={
          isEdit ? <EditIcon color="#323232" /> : <PlusIcon color="var(--color-primary)" />
        }
        onClick={openModal}
      >
        <Text fontWeight={500} color={isEdit ? 'var(--color-nightRider)' : 'primary'}>
          {isEdit ? 'Edit' : 'Add'}
        </Text>
      </Button>
      <Modal
        ref={ref}
        size="xl"
        headerComponent={modalHeader}
        saveButtonText={
          tabIndex < tabNameList.length - 1 && !showImageMeasurements ? 'Next' : 'Save'
        }
        closeButtonText={tabIndex === 0 ? 'Close' : 'Back'}
        onModalClose={closeOrBack}
        onModalSuccess={handleSaveData}
      >
        <MeasurementBodyContent>
          <SelectedMeasurement>
            <div className="revision-dropdown">
              <DropdownField
                label="Measurement"
                options={measurementRevisionList}
                value={
                  !_isNil(selectedRevision)
                    ? { label: selectedRevision.label, value: selectedRevision.value }
                    : undefined
                }
                onChange={handleSelectMeasurement}
                CustomOption={CustomOption}
              />
            </div>
          </SelectedMeasurement>

          {!showImageMeasurements && (
            <CustomMeasurements
              customer_id={customer_id}
              selectedOutfitChipIndex={selectedOutfitChipIndex}
              order_items={order_items}
              customMeasurementDetails={customMeasurementDetails}
              setCustomMeasurementDetails={setCustomMeasurementDetails}
              customMeasurementErrors={customMeasurementErrors}
              setCustomMeasurementErrors={setCustomMeasurementErrors}
              selectedRevision={selectedRevision}
              isModalOpen={isModalOpen}
              setIsMeasurementUpdated={setIsMeasurementUpdated}
              tabIndex={tabIndex}
              setTabIndex={setTabIndex}
              tabNameList={tabNameList}
            />
          )}

          {showImageMeasurements && (
            <ImageMeasurement
              imageMeasurement={imageMeasurement}
              selectedOutfitChipIndex={selectedOutfitChipIndex}
              setImageMeasurement={setImageMeasurement}
              setSelectedRevision={setSelectedRevision}
              setIsMeasurementUpdated={setIsMeasurementUpdated}
            />
          )}
        </MeasurementBodyContent>
      </Modal>
      <Loader showLoader={isLoading} />
    </MeasurementContainer>
  );
};

export default Measurements;
