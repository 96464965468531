import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';
import { BiSolidDownload } from 'react-icons/bi';

import { api } from '../../../../../utils/apiRequest';
import { handleDownloadInvoice } from '../../../../../utils/common';
import {
  Button,
  IconWrapper,
  Loader,
  Popover,
  PopoverMethods,
  Text,
  toasts,
} from '../../../../../ui-component';
import {
  AudioRecordField,
  BulkImageUploadField,
  InputField,
} from '../../../../../components/FormComponents';
import { ChevronDownFilledIcon, MenuIcon } from '../../../../../assets/icons';
import MeasurementSummary from '../../../components/MesurementSummary';
import { outfitStatusOptions } from '../../constant';
import type { OutfitObjType } from '../../type';

import {
  InfoContainer,
  OutfitChipContainer,
  OutfitChipContent,
  OutfitDetailsContainer,
  OutfitSummaryContent,
  OutfitSummaryHeader,
  StatusContainer,
  StatusMenuContainer,
  StatusOptionItemText,
  StatusText,
} from './style';

type OutfitSummaryDetailsProps = {
  outfitList: OutfitObjType[];
  currentItemId: number;
  boutiqueOrderId: number;
  orderNumber: number;
  orderStatus: string;
  setCurrentItemId: (id: number) => void;
};

const OutfitSummaryDetails = ({
  currentItemId,
  orderNumber,
  boutiqueOrderId,
  orderStatus,
  outfitList,
  setCurrentItemId,
}: OutfitSummaryDetailsProps) => {
  const navigate = useNavigate();
  //   const menuRef = useRef<PopoverMethods>(null);
  const statusMenuRef = useRef<PopoverMethods>(null);

  const [itemDetailsById, setItemDetailsById] = useState<Record<number, any>>();
  const [isLoading, setIsLoading] = useState(false);
  const [currentStatus, setCurrentStatus] = useState<keyof typeof outfitStatusOptions>('');

  const [outfitSummaryLink, setOutfitSummaryLink] = useState('');

  const itemDetails = !_isNil(itemDetailsById) ? itemDetailsById[currentItemId] : null;

  useEffect(() => {
    if (_isNil(itemDetailsById) || _isNil(itemDetailsById[currentItemId])) {
      void getItemDetailsById();
    }

    void getOutfitSummaryLink();
  }, [currentItemId]);

  useEffect(() => {
    if (!_isNil(itemDetails)) {
      setCurrentStatus(itemDetails.status);
    }
  }, [JSON.stringify(itemDetails)]);

  const getItemDetailsById = async () => {
    try {
      setIsLoading(true);
      const response = await api.getRequest(`order_item/${currentItemId}`);

      const { status, data } = response;

      if (status) {
        setItemDetailsById({
          ...itemDetailsById,
          [currentItemId]: data,
        });
      }
    } catch (err) {
      if (err instanceof Error) {
        toasts('error', err.message, 'get-order-item-error');
      }
    }

    setIsLoading(false);
  };

  const getOutfitSummaryLink = async () => {
    try {
      const payload = {
        entity_type: 'item_details',
        entity_id: currentItemId,
      };

      setIsLoading(true);
      const response = await api.postRequest(`storage/file/link`, payload);

      const { status, data } = response;

      if (status) {
        setOutfitSummaryLink(data.link);
      }
    } catch (err) {
      if (err instanceof Error) {
        toasts('error', err.message, 'get-outfit-summary-link-error');
      }
    }

    setIsLoading(false);
  };

  const handleEditButton = () => {
    if (orderStatus === 'Drafted') {
      navigate(`/orders/${orderNumber}?formType=edit`);
    } else {
      navigate(`/orders/${orderNumber}/${currentItemId}?formType=edit`);
    }
  };

  const handleUpdateStatus = async (option: Record<string, any>) => {
    try {
      setIsLoading(true);
      const details = { item_status: option.value };
      const response = await api.putRequest(`order_item/${currentItemId}`, details);

      const { status } = response;

      if (status) {
        setCurrentStatus(option.label);
      }
    } catch (err) {
      if (err instanceof Error) {
        toasts('error', err.message, 'update-item-status-error');
      }
    }

    setIsLoading(false);
  };

  const closePopups = () => {
    statusMenuRef.current?.hide();
  };

  //eslint-disable-next-line
  const menuLauncher = (
    <IconWrapper>
      <MenuIcon />
    </IconWrapper>
  );

  const statusLauncher = (
    <StatusContainer status={currentStatus}>
      <StatusText fontWeight={500} status={currentStatus}>
        {currentStatus}
      </StatusText>

      {outfitStatusOptions[currentStatus].length > 0 && <ChevronDownFilledIcon />}
    </StatusContainer>
  );

  if (_isNil(itemDetailsById) || _isNil(itemDetailsById[currentItemId])) {
    return null;
  }

  return (
    <OutfitDetailsContainer onClick={closePopups}>
      <OutfitSummaryHeader>
        <Text fontWeight={500}> Outfit Details</Text>
        <div className="header-btn-container">
          <Button appearance="outlined" onClick={handleEditButton}>
            Edit
          </Button>
          <Button
            appearance="outlined"
            onClick={() => {
              handleDownloadInvoice(
                outfitSummaryLink,
                `${boutiqueOrderId ?? ''}_${itemDetails.outfit_alias}_outfit_summary.pdf`
              );

              //   window.open(invoiceLink, '_blank');
            }}
            trailingIcon={<BiSolidDownload color="var(--color-primary)" size={16} />}
          >
            Download pdf
          </Button>
        </div>
        {/* <Popover statusMenuR{menuRef} laucher={menuLauncher}>
          <div className="menu-container">
            <div className="menu-item">Edit</div>
          </div>
        </Popover> */}
      </OutfitSummaryHeader>
      <OutfitSummaryContent>
        <OutfitChipContainer>
          {outfitList.map((outfit, index) => (
            <OutfitChipContent
              key={index}
              isSelected={currentItemId === outfit.item_id}
              onClick={() => setCurrentItemId(outfit.item_id)}
            >
              <img src={outfit.outfit_type_image_link ?? ''} alt={outfit.outfit_type} />
              <Text size="small" fontWeight={600} color="black">
                {outfit.outfit_alias}
              </Text>
            </OutfitChipContent>
          ))}
        </OutfitChipContainer>

        <InfoContainer>
          <div className="info-item">
            <Text>Item Status</Text>
            {currentStatus !== 'Drafted' ? (
              <Popover ref={statusMenuRef} laucher={statusLauncher}>
                <StatusMenuContainer>
                  {outfitStatusOptions[currentStatus]
                    .filter((option) => option.label !== currentStatus)
                    .map((option, index) => (
                      <div
                        key={index}
                        className="stitch-option-item"
                        onClick={() => handleUpdateStatus(option)}
                      >
                        <StatusOptionItemText status={option.label}>
                          {option.label}
                        </StatusOptionItemText>
                      </div>
                    ))}
                </StatusMenuContainer>
              </Popover>
            ) : (
              <>{statusLauncher}</>
            )}
          </div>
          <div className="info-item">
            <div className="info-item-right">
              <Text fontWeight={600}>Order Number:</Text>
              <Text color="black" fontWeight={500}>
                {boutiqueOrderId}
              </Text>
            </div>
            <div className="info-item-right">
              <Text fontWeight={600}>Delivery Date:</Text>
              <Text color="black" fontWeight={500}>
                {!_isNil(itemDetails.delivery_date) && !_isEmpty(itemDetails.delivery_date)
                  ? new Date(itemDetails.delivery_date).toDateString()
                  : '-'}
              </Text>
            </div>
            <div className="info-item-right">
              <Text fontWeight={600}>Trial Date:</Text>
              <Text color="black" fontWeight={500}>
                {!_isNil(itemDetails.trial_date) && !_isEmpty(itemDetails.trial_date)
                  ? new Date(itemDetails.trial_date).toDateString()
                  : '-'}
              </Text>
            </div>
          </div>
        </InfoContainer>

        <MeasurementSummary
          customMeasurementDetails={
            itemDetails?.measurement_details?.inner_measurement_details ?? []
          }
          measurementImageLink={itemDetails?.measurement_details?.measurement_image_link ?? ''}
          stitchOptions={itemDetails?.order_item_stitch_options}
        />

        {!_isEmpty(itemDetails?.special_instructions) && (
          <InputField
            label="Special Instructions"
            placeholder="Write Instructions given by customer"
            type="textarea"
            required={false}
            value={itemDetails?.special_instructions ?? ''}
            disabled={true}
          />
        )}

        {!_isEmpty(itemDetails?.audio_file_details) && (
          <AudioRecordField
            label="Record Audio"
            type="file"
            required={false}
            value={itemDetails?.audio_file_details ?? []}
            multiple={true}
            maxUpload={5}
            disabled={true}
          />
        )}

        {!_isEmpty(itemDetails?.inspiration) && (
          <div>
            <Text fontWeight={500}>Add Inspiration</Text>
            <div
              className="inspiration-text"
              onClick={() => {
                const link = itemDetails.inspiration.includes('https://')
                  ? itemDetails.inspiration
                  : `https://${itemDetails.inspiration}`;
                window.open(link, '_blank');
              }}
            >
              <Text color="primary" fontWeight={400}>
                {itemDetails.inspiration ?? ''}
              </Text>
            </div>
          </div>
          //   <InputField
          //     label="Add Inspiration"
          //     placeholder="https://www.google.com"
          //     type="textarea"
          //     required={false}
          //     value={itemDetails?.inspiration ?? ''}
          //     disabled={true}
          //   />
        )}

        {!_isEmpty(itemDetails?.cloth_image_file_details) && (
          <BulkImageUploadField
            label="Upload Cloth Images"
            placeholder="Upload Cloth Images"
            type="file"
            required={false}
            multiple={true}
            maxUpload={10}
            value={itemDetails?.cloth_image_file_details ?? []}
            disabled={true}
          />
        )}
      </OutfitSummaryContent>

      <Loader showLoader={isLoading} />
    </OutfitDetailsContainer>
  );
};

export default OutfitSummaryDetails;
