import type { IconProps } from './types';

const EditIcon = (props: IconProps) => {
  const { width = '20', height = '20', color = 'var(--color-nightRider)' } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#editIconID)">
        <path
          d="M2.5 14.3751V17.5001H5.625L14.8417 8.28346L11.7167 5.15846L2.5 14.3751ZM17.2583 
          5.8668C17.5833 5.5418 17.5833 5.0168 17.2583 4.6918L15.3083 2.7418C14.9833 
          2.4168 14.4583 2.4168 14.1333 2.7418L12.6083 4.2668L15.7333 7.3918L17.2583 5.8668Z"
          fill={color}
          fillOpacity="0.7"
        />
      </g>
      <defs>
        <clipPath id="editIconID">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EditIcon;
