import type { IconProps } from './types';

const PersonAddIcon = (props: IconProps) => {
  const { width = '26', height = '20', color = 'var(--color-bondiBlue)' } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 26 20" fill="none">
      <path
        /* eslint-disable-line */

        d="M14.167 5.33329C14.167 2.75496 12.0787 0.666626 9.50033 0.666626C6.92199 0.666626 4.83366 2.75496 4.83366 5.33329C4.83366 7.91163 6.92199 9.99996 9.50033 9.99996C12.0787 9.99996 14.167 7.91163 14.167 5.33329ZM11.8337 5.33329C11.8337 6.61663 10.7837 7.66663 9.50033 7.66663C8.21699 7.66663 7.16699 6.61663 7.16699 5.33329C7.16699 4.04996 8.21699 2.99996 9.50033 2.99996C10.7837 2.99996 11.8337 4.04996 11.8337 5.33329ZM0.166992 17V19.3333H18.8337V17C18.8337 13.8966 12.6153 12.3333 9.50033 12.3333C6.38533 12.3333 0.166992 13.8966 0.166992 17ZM2.50033 17C2.73366 16.1716 6.35033 14.6666 9.50033 14.6666C12.6387 14.6666 16.2437 16.16 16.5003 17H2.50033ZM22.3337 13.5V9.99996H25.8337V7.66663H22.3337V4.16663H20.0003V7.66663H16.5003V9.99996H20.0003V13.5H22.3337Z"
        fill={color}
      />
    </svg>
  );
};

export default PersonAddIcon;
