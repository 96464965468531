import type { IconProps } from './types';

const ProfilePicIcon = (props: IconProps) => {
  const { width = '100', height = '100' } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 100 100" fill="none">
      <g clipPath="url(#profileIconID)">
        <path
          d={`M50.0002 8.33325C27.0002 8.33325 8.3335 26.9999 8.3335 49.9999C8.3335
           72.9999 27.0002 91.6666 50.0002 91.6666C73.0002 91.6666 91.6668 72.9999
            91.6668 49.9999C91.6668 26.9999 73.0002 8.33325 50.0002 8.33325ZM50.0002
             20.8333C56.9168 20.8333 62.5002 26.4166 62.5002 33.3333C62.5002 40.2499 
             56.9168 45.8333 50.0002 45.8333C43.0835 45.8333 37.5002 40.2499 37.5002 
             33.3333C37.5002 26.4166 43.0835 20.8333 50.0002 20.8333ZM50.0002 79.9999C39.5835 
             79.9999 30.3752 74.6666 25.0002 66.5832C25.1252 58.2916 41.6668 53.7499 50.0002 
             53.7499C58.2918 53.7499 74.8752 58.2916 75.0002 66.5832C69.6252 74.6666 60.4168 
             79.9999 50.0002 79.9999Z`}
          fill="#32343A"
          fillOpacity="0.11"
        />
      </g>
      <defs>
        <clipPath id="profileIconID">
          <rect width="100" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ProfilePicIcon;
