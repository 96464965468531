export const GENDER_OPTION = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
  //   {
  //     label: 'Others',
  //     value: 'others',
  //   },
];
