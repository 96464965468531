import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

import { getInitailsOfName } from '../../../../../../../utils/common';
import { ChevronLeftIcon, RightArrowIcon } from '../../../../../../../assets/icons';
import { Button, IconWrapper, Text } from '../../../../../../../ui-component';

import type { OrderItemType } from '../../../../type';

import { ActionContainer, CustomerDetails, SelectedCustomerContainer } from '../../../commonStyles';
import { useEffect, useState } from 'react';

type SelectedCustomerInfoProps = {
  customer_details: Record<string, any>;
  order_items: OrderItemType[];
  isItemUpdate: boolean;
  handleSaveOrder: () => void;
  handleContinueClick: () => void;
  handleUpdateItem: () => void;
  handleBackClick: () => void;
  isValidDetails: boolean;
  showOrderSummary?: boolean;
};

export const SelectedCustomerInfo = ({
  customer_details,
  order_items,
  isItemUpdate = false,
  handleSaveOrder,
  handleUpdateItem,
  handleContinueClick,
  handleBackClick,
  isValidDetails = false,
  showOrderSummary = false,
}: SelectedCustomerInfoProps) => {
  const [isContinueDisable, setIsContinueDisable] = useState(true);

  const { customer_name = '', phone_number = '', profile_pic_link = '' } = customer_details;

  useEffect(() => {
    let isEnable = isValidDetails;

    order_items.forEach((item) => {
      if (_isNil(item.delivery_date) || _isEmpty(item.delivery_date)) {
        isEnable = false;
      }

      if (_isNil(item.price_breakup) || _isEmpty(item.price_breakup)) {
        isEnable = false;
      } else {
        const stitchingPriceObj = item.price_breakup.find(
          (obj) => obj.component === 'Stitching Cost'
        );

        if (_isNil(stitchingPriceObj) || _isNil(stitchingPriceObj.value)) {
          isEnable = false;
        }

        const otherPrice =
          item.price_breakup?.filter((price) => price.component !== 'Stitching Cost') ?? [];

        otherPrice.map((price) => {
          if (_isNil(price.component) || _isEmpty(price.component)) {
            isEnable = false;
          }
        });
      }
    });

    setIsContinueDisable(!isEnable);
  }, [JSON.stringify(order_items)]);

  return (
    <SelectedCustomerContainer>
      {!showOrderSummary ? (
        <CustomerDetails>
          <>
            {_isEmpty(profile_pic_link) ? (
              <div className="profile-pic">{getInitailsOfName(customer_name).toUpperCase()}</div>
            ) : (
              <img className="profile-pic" src={profile_pic_link} alt="profile-pic" />
            )}
          </>
          <div>
            <Text color="black" fontWeight={700}>
              {customer_name}
            </Text>
            <Text size="small" fontWeight={600}>
              {phone_number}
            </Text>
          </div>
        </CustomerDetails>
      ) : (
        <IconWrapper onClick={handleBackClick}>
          <ChevronLeftIcon />
        </IconWrapper>
      )}
      <ActionContainer>
        {!isItemUpdate && (
          <Button
            appearance="outlined"
            bgColor="var(--color-nightRider)"
            disabled={!isValidDetails}
            onClick={handleSaveOrder}
          >
            Save as draft
          </Button>
        )}
        {!isItemUpdate && (
          <Button
            trailingIcon={<RightArrowIcon />}
            disabled={isContinueDisable}
            onClick={handleContinueClick}
          >
            Save And Continue
          </Button>
        )}

        {isItemUpdate && (
          <Button disabled={isContinueDisable} onClick={() => handleUpdateItem()}>
            Save
          </Button>
        )}
      </ActionContainer>
    </SelectedCustomerContainer>
  );
};
