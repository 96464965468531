import { Colors } from './Colors';
import Reset from './Reset';

const GlobalStyle = () => {
  return (
    <>
      <Colors />
      <Reset />
    </>
  );
};

export default GlobalStyle;
