import { createGlobalStyle } from 'styled-components';

export const Colors = createGlobalStyle`
    :root {
        --color-primary: #4D7AFF;
        --color-secondary: #2177FF;
        --color-tertiary: #F55F71;
        --color-disabled: #f2f2f2;
        --color-btn-disabled: #b8b8b8;

        --color-white: #fff;
        --color-black: #000;

        --color-ghost: #bdbec2;
        --color-darkGray: #A8A8A8;
        --color-lavender: #F8F8FD;
        --color-ebonyClay: #32343a33;
        --color-nightRider: #323232cc;
        --color-islamicGreen: #30BC00;
        --color-neonCarrot: #FF993A;
        --color-bondiBlue: #06A0B5;
        --color-solitude: #E9EBF4;
        --color-slateGrey: #7C8293;
        --color-gainsboro: #D9D9D9;
        --color-maroon: #723100;
        --color-darkGoldenrod: #AA8F00;
        --color-pumpkin: #FF800C;
        --color-denim: #167BD8;
        --color-green: #0F6F00;
        --color-mortar: #525252;
        --color-midnightExpress: #0F121D;
        --color-paleVioletRed: #DF75A5;
        --color-papayaWhip :#FFEBDC;
        --color-honeydew: #EEFFEB;
        --color-seashell: #FFF7F0;
        --color-aliceBlue: #EEF7FF
        --color-lightYellow: #FFFBE4;
    }
`;
