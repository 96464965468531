export const TAILOR_OPTIONS = [
  {
    label: 'Gents Tailor',
    value: '1',
  },
  {
    label: 'Ladies Tailor',
    value: '2',
  },
  {
    label: 'Both',
    value: '3',
  },
];
