import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import _isNil from 'lodash/isNil';

import { getValueFromLocalStorage } from '../utils/common';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';

import { ContentContainer, PrivateContainer, RightContainer } from './style';

type PrivateWrapperProps = {
  Content: () => JSX.Element | null;
  sidebarRequired?: boolean;
};

const PrivateWrapper = ({ Content, sidebarRequired = true }: PrivateWrapperProps) => {
  const navigate = useNavigate();
  const token = getValueFromLocalStorage('token');

  useEffect(() => {
    if (_isNil(token) || token === '') {
      navigate('/login');
    }
  }, [token]);

  return (
    <>
      <PrivateContainer sidebarRequired={sidebarRequired}>
        <Sidebar />
        <RightContainer>
          <Header />
          <ContentContainer>
            <Content />
          </ContentContainer>
        </RightContainer>
        {/* {sidebarRequired ? (
          <>
            <Sidebar />
            <RightContainer>
              <Header />
              <ContentContainer>
                <Content />
              </ContentContainer>
            </RightContainer>
          </>
        ) : (
          <>
            <PublicHeader />
            <ContentContainer hasHeader={true}>
              <Content />
            </ContentContainer>
          </>
        )} */}
      </PrivateContainer>
    </>
  );
};

export default PrivateWrapper;
