import styled from 'styled-components';

export const StitchOptionsContainer = styled.div`
  display: flex;
  gap: 48px;
`;

export const OptionListContainer = styled.div`
  margin: 12px auto;
`;
