import type { IconProps } from './types';

const CheckmarkIcon = (props: IconProps) => {
  const { width = '28', height = '28', color = 'var(--color-darkGray)' } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none">
      <g clipPath="url(#checkmarkIconID)">
        <path
          /* eslint-disable-line */
          d="M21 10.5L19.355 8.84329L11.6667 16.5316L8.645 13.5216L7 15.1666L11.6667 19.8333L21 10.5ZM22.1667 3.49996H17.29C16.8 2.14663 15.5167 1.16663 14 1.16663C12.4833 1.16663 11.2 2.14663 10.71 3.49996H5.83333C5.67 3.49996 5.51833 3.51163 5.36667 3.54663C4.91167 3.63996 4.50333 3.87329 4.18833 4.18829C3.97833 4.39829 3.80333 4.65496 3.68667 4.93496C3.57 5.20329 3.5 5.50663 3.5 5.83329V22.1666C3.5 22.4816 3.57 22.7966 3.68667 23.0766C3.80333 23.3566 3.97833 23.6016 4.18833 23.8233C4.50333 24.1383 4.91167 24.3716 5.36667 24.465C5.51833 24.4883 5.67 24.5 5.83333 24.5H22.1667C23.45 24.5 24.5 23.45 24.5 22.1666V5.83329C24.5 4.54996 23.45 3.49996 22.1667 3.49996ZM14 3.20829C14.4783 3.20829 14.875 3.60496 14.875 4.08329C14.875 4.56163 14.4783 4.95829 14 4.95829C13.5217 4.95829 13.125 4.56163 13.125 4.08329C13.125 3.60496 13.5217 3.20829 14 3.20829ZM22.1667 22.1666H5.83333V5.83329H22.1667V22.1666Z"
          fill={color}
          fillOpacity="0.75"
        />
      </g>
      <defs>
        <clipPath id="checkmarkIconID">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckmarkIcon;
