import type { IconProps } from './types';

const StarIcon = (props: IconProps) => {
  const { width = '24', height = '24', color = 'var(--color-neonCarrot)' } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M21.9362 10.8281L17.6987 14.3531L19.0393 19.8C19.101 20.0283 19.1094
        20.2677 19.0641 20.4998C19.0187 20.7319 18.9207 20.9505 18.7777 
        21.1389C18.6346 21.3272 18.4503 21.4802 18.2389 21.5861C18.0275 21.6921
        17.7945 21.7481 17.5581 21.75C17.2684 21.7487 16.9853 21.6641 16.7424
        21.5062L12.0081 18.5156H11.9893L7.59244 21.3C7.3084 21.4792 6.97736 21.5696
        6.64167 21.5596C6.30597 21.5495 5.98091 21.4396 5.70807 21.2438C5.42697 
        21.0386 5.21408 20.7537 5.09704 20.426C4.98001 20.0983 4.96425 19.743 5.05182
        19.4062L6.31744 14.4281L2.06119 10.8281C1.81971 10.6263 1.64422 10.3569 
        1.55733 10.0544C1.47044 9.75197 1.47614 9.43043 1.57369 9.13125C1.66869 8.83659
        1.84992 8.57722 2.09394 8.38668C2.33796 8.19614 2.63354 8.08321 2.94244 
        8.0625L8.47369 7.70625L10.5737 2.475C10.6863 2.1885 10.8823 1.94241 11.1364 
        1.76858C11.3905 1.59475 11.6909 1.50118 11.9987 1.5C12.3065 1.50118 12.6069 1.59475 
        12.861 1.76858C13.115 1.94241 13.3111 2.1885 13.4237 2.475L15.4862 7.67813L21.0549 
        8.0625C21.3642 8.08195 21.6604 8.19441 21.9047 8.38513C22.1489 8.57585 22.3298 
        8.83592 22.4237 9.13125C22.5212 9.43043 22.5269 9.75197 22.4401 10.0544C22.3532 
        10.3569 22.1777 10.6263 21.9362 10.8281Z"
        fill={color}
      />
    </svg>
  );
};

export default StarIcon;
