import _isNil from 'lodash/isNil';
import { Text } from '../../../../ui-component';
import {
  PendingAmountIcon,
  FolderIcon,
  CheckmarkIcon,
  PersonAddIcon,
} from '../../../../assets/icons';
import { convertNumberOrStringToPriceFormat } from '../../../../utils/common';
import { InfoBox } from '../InfoBox';

type GlobalInfoProps = {
  customer_dashboard: Record<string, any>;
  ledger_dashboard_data: Record<string, any>;
};

const GlobalInfo = (props: GlobalInfoProps) => {
  const { customer_dashboard, ledger_dashboard_data } = props;

  return (
    <>
      <InfoBox
        icon={
          <Text size="xxxl" fontWeight={500} color="var(--color-islamicGreen)">
            &#8377;
          </Text>
        }
        label="Amount Received"
        amount={
          !_isNil(ledger_dashboard_data?.amount_recieved)
            ? convertNumberOrStringToPriceFormat(ledger_dashboard_data.amount_recieved.toFixed(2))
            : '-'
        }
        amountTextColor="var(--color-islamicGreen)"
      />
      <InfoBox
        icon={<PendingAmountIcon />}
        label="Pending Amount (All Time)"
        amount={
          !_isNil(ledger_dashboard_data?.pending_amount)
            ? convertNumberOrStringToPriceFormat(ledger_dashboard_data.pending_amount.toFixed(2))
            : '-'
        }
        amountTextColor="var(--color-tertiary)"
        amountLeadingIcon={
          <Text size="medium" fontWeight={700} color="var(--color-tertiary)">
            &#8377;
          </Text>
        }
      />
      <InfoBox
        icon={<FolderIcon />}
        label="Active Orders (All Time)"
        amount={ledger_dashboard_data?.active_order_count ?? '-'}
        amountTextColor="var(--color-primary)"
      />

      <InfoBox
        icon={<CheckmarkIcon />}
        label="Closed Orders"
        amount={ledger_dashboard_data?.closed_order_count ?? '-'}
        amountTextColor="var(--color-black)"
      />

      <InfoBox
        icon={<PersonAddIcon />}
        label="New Customers"
        amount={customer_dashboard?.new_customers_added ?? '-'}
        amountTextColor="var(--color-bondiBlue)"
        amountLeadingIcon={
          <Text size="large" fontWeight={700} color="var(--color-bondiBlue)">
            +
          </Text>
        }
      />
    </>
  );
};

export default GlobalInfo;
