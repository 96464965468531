import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  .checkbox-input-div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .checkbox-input {
    cursor: pointer;
  }
`;

export const LabelContainer = styled.div`
  display: flex;
  margin-left: 12px;

  .mandatory {
    vertical-align: sub;
  }
`;
