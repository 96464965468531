import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import _isNil from 'lodash/isNil';
import { IoChevronBackOutline } from 'react-icons/io5';

import { RootState } from '../../store';
import { routePathOptions } from '../../utils/contant';
import { IconWrapper, Text } from '../../ui-component';

import { Container, HeaderInfo, Circle } from './style';

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname, search } = location;
  const { onboardingReducer, commonDataReducer } = useSelector((state: RootState) => state);
  const { boutiqueData } = onboardingReducer;
  const { enableHeaderBackButton } = commonDataReducer.headerData;

  const { admin_tailor_name = '', admin_tailor_profile_pic_url = '' } = boutiqueData || {};

  const getCurrentRoute = () => {
    return routePathOptions.filter((route) => {
      const currentPath = pathname.split('/')[1];

      if (route.path.includes(currentPath)) {
        return true;
      }

      return false;
    });
  };

  const getHeaderTitle = useMemo(() => {
    const currentRoute = getCurrentRoute();

    if (currentRoute.length > 0) {
      const searchParams = new URLSearchParams(search.substring(1));
      const pathFormType = searchParams.get('formType') ?? '';

      return !_isNil(currentRoute[0].formType) && !_isNil(currentRoute[0].formType[pathFormType])
        ? currentRoute[0].formType[pathFormType]
        : currentRoute[0].label;
    }

    return '';
  }, [pathname, search]);

  const currentRoute = getCurrentRoute();
  const showBack = currentRoute.length > 0 ? currentRoute[0].showBackIcon : false;

  return (
    <Container>
      <div className="title-container">
        {showBack && (
          <IconWrapper
            onClick={() => {
              if (enableHeaderBackButton) {
                navigate(-1);
              }
            }}
          >
            <IoChevronBackOutline id="header-back-icon" />
          </IconWrapper>
        )}
        <Text size="xl" fontWeight={700}>
          {getHeaderTitle}
        </Text>
      </div>
      <HeaderInfo>
        <Circle>
          <img src={admin_tailor_profile_pic_url} alt="profile-pic" />
        </Circle>
        <Text fontWeight={600}>{admin_tailor_name}</Text>
      </HeaderInfo>
    </Container>
  );
};

export default Header;
